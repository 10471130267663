:root[data-dtinth] {
  --background-body: #353433;
  --background: #090807;
  --background-alt: #252423;
  --selection: #d7fc70;
  --text-main: #e9e8e7;
  --text-bright: #fff;
  --border: #656463;
  --text-muted: #8b8685;
  --links: #ffb;
  --code: #e9d7c5;
  --scrollbar-thumb: #d7fc70;
  --focus: #8b8685;
  font-size: 17px;
}
::selection {
  color: #000;
}
[data-dtinth] body {
  font-family: Arimo, Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.01em;
}
[data-dtinth] body {
  line-height: 1.75;
}
[data-dtinth] h1 {
  color: #8b8685;
  line-height: 1.4;
  letter-spacing: 0.02em;
}
[data-dtinth] h2 {
  color: #d7fc70;
  text-shadow: 2px 2px RGBA(215, 252, 112, 0.15);
  margin-top: 2em;
  letter-spacing: 0.03em;
}
[data-dtinth] h3 {
  color: #febfea;
  text-shadow: 2px 2px RGBA(0, 0, 0, 0.25);
  margin-top: 2.5em;
  letter-spacing: 0.03em;
}
[data-dtinth] code {
  background: #252423;
  font-family: Comic Mono, Menlo, Consolas, sans-serif;
  letter-spacing: 0.01em;
}
[data-dtinth] :not(pre) > code {
  border: 1px solid #555453;
  box-shadow: 0 1px 0 #252423;
  border-radius: 2px;
  border-bottom-width: 2px;
  background: #252423;
}
[data-dtinth] figure {
  text-align: center;
}
[data-dtinth] figcaption {
  font-style: italic;
  color: #bef;
  margin-top: -1em;
}
[data-dtinth] footer,
[data-dtinth] footer a {
  color: #8b8685;
}
[data-dtinth] li {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
[data-dtinth] blockquote > :first-child {
  margin-top: 0;
}
[data-dtinth] blockquote > :last-child {
  margin-bottom: 0;
}

/* https://github.com/kognise/water.css/pull/238 */
a > code,
a > strong {
  color: inherit;
}
